<template>
	<div class="block-inner">
		<header @change="$emit('edit')">
			<div>
				<RichEditor v-model="block.content" :inline="true" :block-id="block.id" :paste-tags="false" placeholder="Write a statement" @input="$emit('edit')" />
			</div>
		</header>

		<div class="input-group">
			<div v-for="(option, index) in options" :key="index" class="input-group-row">
				<BoolInput v-model="option.isCorrect" :checked="option.isCorrect" :label="option.label" :radio="true" :name="block.id + '-correct'" @input="setOptions(option.id)" />
			</div>
		</div>
	</div>
</template>

<script>
	import {v4} from 'uuid';
	import BoolInput from '../BoolInput';
	import RichEditor from '../RichEditor';

	export default {
		components: {
			BoolInput,
			RichEditor
		},

		props: {
			block: {
				type: Object,
				required: true
			}
		},

		data() {
			return {
				optionsGroup: ''
			};
		},

		computed: {
			options() {
				if(this.block.options && this.block.options[this.optionsGroup]) {
					return this.block.options[this.optionsGroup];
				}

				return [];
			}
		},

		created() {
			if(this.block.options && Object.keys(this.block.options).length) {
				this.optionsGroup = Object.keys(this.block.options).pop();
			}
			else {
				this.optionsGroup = v4();
				this.$set(this.block, 'options', {[this.optionsGroup]: []});

				this.block.options[this.optionsGroup] = [
					{
						id: v4(),
						groupId: this.optionsGroup,
						label: 'True',
						isCorrect: true
					},
					{
						id: v4(),
						groupId: this.optionsGroup,
						label: 'False',
						isCorrect: false
					}
				];
			}
		},

		methods: {
			setOptions(correctOption) {
				if(correctOption) {
					this.options.forEach(option => {
						option.isCorrect = option.id === correctOption;
					});
				}
				else {
					this.options[0].isCorrect = true;
				}
			}
		}
	};
</script>

<style lang="scss" scoped>
	.input-group {
		margin-top: $default_padding / 2;
	}
</style>